/* HTML & BODY */
* {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

html {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

body {
  background-color: rgb(247, 242, 233);
  margin: 0;
  overflow-x: hidden;
}

/* COLORS */
.main-color {
  color: #3f3e44;
}

.secondary-color {
  color: #d9d3cf;
}

.accent {
  color: #d16571;
}

/* FONTS */
.lato {
  font-family: 'Lato', sans-serif;
}

.bayon {
  font-family: 'Bayon', sans-serif;
}

h1 {
  font-family: 'Bayon', sans-serif;
  letter-spacing: 6px;
  font-size: calc(48px + 1.5vw);
}

h2 {
  font-size: calc(24px + 1vw);
  font-weight: 600;
}

h3 {
  font-size: calc(22px + 0.4vw);
  font-weight: 500;
}

h4 {
  font-weight: 400;
}

p {
  font-size: calc(14px + 0.4vw);
}

/* LAYOUTS */
canvas {
  max-height: 800px;
}

section {
  scroll-snap-align: start;
  height: 100vh;
}

.column-section {
  display: block;
  height: 50vh;
}

@media (min-width: 1100px) {
  .column-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100vh;
  }
}

.center {
  text-align: center;
  margin: 20px;
}

.vert-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


/* NAVIGATION */
.blob-message {
  position: absolute;
  top: 15%;
  left: 10%;
  height: 60px;
  width: 60px;
  padding: 20px;
  background: #3f3e44;
  color:  rgb(247, 242, 233);
  border-radius: 50%;
  transition: opacity 1s ease-out;
  opacity: 1;
  text-align: center;
}

.blob-message.fade-out {
  opacity: 0;
}

.navigation {
  position: fixed;
  top: 15vh;
  right: 0;
  padding: 10px 20px;
  background-color: grey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transition: opacity 0.3s ease;
  opacity: 0;
  z-index: 1000;
  list-style: none;
  border-radius: 10px 0px 0px 10px;
}

.navigation.visible {
  opacity: 1;
}

.navigation li {
  padding: 5px 0;
}

.navigation li a {
  text-decoration: none;
  color: white;
}

.navigation li a:hover {
  color: #eba9b1;
}

.navigation li a.active {
  color: #eba9b1;
}

.resume-button {
  padding: 10px 20px;
  color: #d16571;
  background-color: rgb(247, 242, 233);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 40px;
  border: 2px solid #d16571;
  transition: all 0.3s ease;
}

.resume-button:hover {
  color: rgb(247, 242, 233);
  background-color: #eba9b1;
  border: 2px solid #d16571;
}

/* ABOUT ME SECTION */
#about-me {
  color: #3f3e44;
}

.my-info {
  padding: 0px 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.my-info h2 {
  margin-top: 40px;
  margin-bottom: 20px;
}

.my-info h4 {
  margin-bottom: 20px;
}

.my-info h3 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.about-me-content h2 {
  font-size: calc(28px + 1vw);
  color: #d16571; 
  margin-bottom: 20px;
}

.about-me-content p {
  font-size: calc(16px + 0.5vw);
  line-height: 1.6;
}

.technologies {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
  padding: 0px 100px 0px 100px;
}

.technology-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 2px solid #eba9b1;
  overflow: hidden;
  padding: 0.5rem;
  background-color: white;
}

.technology-circle img {
  width: 100%;
  height: auto;
}

.technology-circle p {
  margin-top: 0.5rem;
  text-align: center;
  font-size: 0.9rem;
}

/* PROJECTS SECTION */
.carousel-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  height: 65vh;
}

.carousel-content {
  flex: 1;
  display: flex;
  justify-content: center;
}

.carousel-inner {
  display: flex;
  transition: transform 0.3s ease;
}

.carousel-button {
  background: #d16571;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  transform: translateY(-50%);
}

.carousel-button:hover {
  background-color: #b75151;
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}

.project-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 250px;
  width: 100%;
  margin: 0 10px;
  transition: transform 0.3s ease;
  position: relative;
}

.project-card:hover {
  transform: translateY(-5px);
}

.project-card img {
  width: 100%;
  border-bottom: 2px solid #eee;
}

.project-card h3 {
  font-size: 1.5rem;
  margin-top: 10px;
  margin-bottom: 15px;
}

.project-card p {
  font-size: 1rem;
  margin-bottom: 15px;
  color: #555;
}

.project-links {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}

.project-button {
  padding: 8px 12px;
  border-radius: 5px;
  background-color: #d16571;
  color: #fff;
  font-size: 0.9rem;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.project-button:hover {
  background-color: #b75151;
}

.project-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.project-tag {
  background-color: #e1caca;
  color: #9b1f35;
  font-size: 0.85rem;
  padding: 5px 10px;
  border-radius: 20px;
  display: inline-block;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  #projects h2 {
    font-size: 2rem;
  }

  .carousel-button {
    width: 30px;
    height: 30px;
    font-size: 1.2rem;
  }

  .project-card {
    margin: 0 5px;
  }
}

/* ACHIEVEMENTS SECTION */
.achievement-cards {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;
}

.achievement-card {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border: 2px solid #eba9b1;
}

.achievement-card h3 {
  margin: 0 20px 0 0;
  font-size: calc(18px + 0.2vw);
  color: #d16571;
}

.achievement-card p {
  margin: 0;
  color: #333;
}

/* CONTACT FOOTER */

.footer h1 {
  padding-top: 50px;
}

footer img {
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 100%);
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 100%); 
}

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#footer-links {
  padding-top: 0 !important;
}

.footer-title {
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}

.footer-title::after {
  content: "";
  display: block;
  width: 50px;
  height: 1px;
  background-color: #3f3e44;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.flex-row-1 {
  display: flex;
  list-style: none;
  margin-top: 30px;
}

.flex-row {
  display: flex;
  justify-content: center;
  list-style: none;
}

.footer-title {
  position: relative;
  text-align: center;
}

.nav-link {
  font-size: 30px;
  margin: 10px;
}

.nav-link:hover {
  color: #eba9b1;
}

@media screen and (max-width: 920px) {
  .footer {
    margin: 0;
    margin-bottom: 100px;
  }
}

/* RESPONSIVENESS ADJUSTMENTS*/
@media screen and (max-width: 1100px) {
  .my-info {
    padding: 0;
  }

  canvas {
    padding-top: 50px;
    max-height: 55vh; 
  }

  .vert-center {
    margin: 80px;
  }

  .my-info {
    margin: 80px;
  }

  .navigation {
    top: 0vh;
    flex-direction: row;
    gap: 15px;
    right: 0;
    left: 0;
    border-radius: 0px 0px 10px 10px;
  }

  .technologies {
    gap: 5px;
    padding: 0;
    margin: 0;
  }

  .technology-circle {
    width: 50px;
    height: 50px;
  }
  
  .footer {
    margin-bottom: 0;
    height: auto;
  }
}

@media screen and (max-width: 720px) {
  .blob-message {
    top: 2%;
    left: 2%;
  }
  
  canvas {
    height: 100vh !important;  
  }

  .my-info {
    margin: 20px;
  }

  .vert-center {
    margin: 20px;
  }

  .vert-center h1 {
    font-size: calc(42px + 1.5vw);
  }

  .navigation {
    gap: 8px;
  }
}

@media screen and (max-width: 430px) {
  canvas {
    padding-top: 0px;
  }

  .vert-center {
    margin: 15px;
  }
  
  .vert-center h1 {
    font-size: calc(36px + 1.5vw);
  }

  .achievement-card h3 {
    font-size: 16px;
  }

  .achievement-card p {
    font-size: 12px;
  }

  .navigation li {
    font-size: 12px;
  }
}


@media screen and (max-width: 376px) {
  canvas {
    padding-top: 0px;
  }

  .vert-center {
    margin: 15px;
  }
  
  .vert-center h1 {
    font-size: calc(30px + 1.2vw);
  }

  .achievement-card p {
    font-size: 12px;
  }

  .technology-circle {
    width: 40px;
    height: 40px;
  }

  .achievement-card h3 {
    font-size: 12px;
  }

  .achievement-card {
    padding: 12px;
  }

  .navigation li {
    font-size: 12px;
  }
}
